import { Layout } from "@components";

import TutorialIndex from "@content/tutorial/using-dotter/index.en";

export default function Tutorial() {
  return (
    <Layout title="Using Dotter.science in Everyday Life">
      <TutorialIndex currenttuto="usingdotter" />
    </Layout>
  );
}
